import { tns } from '@paybis/tiny-slider/src/tiny-slider';
import NewsService from '../services/news';
import { getLocaleDirection } from '../helpers/helper';

const BreakingNewsBlock = {
  init() {
    this._initCloseButton();

    const breakingNewsSlides = document.querySelectorAll(
      '.announcement-top__slide',
    );

    if (breakingNewsSlides.length < 2) {
      // XXX: we don't need the slider if there is only one element, or no elements at all
      return;
    }

    this._initCarousel();
  },

  _initCloseButton() {
    const closeButton = document.querySelector('.announcement-top__close');

    if (closeButton === null) {
      return;
    }

    closeButton.addEventListener('click', () => {
      const breakingNews = document.querySelector('.announcement-top');
      if (breakingNews !== null) {
        breakingNews.style.display = 'none';
      }
      NewsService.closeBreakingNews();
    });
  },

  _initCarousel() {
    const slider = tns({
      container: '.announcement-top__carousel-container',
      autoplay: true,
      slideBy: 'page',
      nextButton: '.announcement-top__nav-item--next',
      prevButton: '.announcement-top__nav-item--prev',
      nav: false,
      controls: true,
      controlsContainer: false,
      textDirection: getLocaleDirection(),
    });

    slider.events.on('transitionEnd', ({ displayIndex, slideCount }) => {
      const navLabel = document.querySelector('.announcement-top__nav span');
      if (navLabel === null) {
        return;
      }
      navLabel.textContent = displayIndex + '/' + slideCount;
    });

    const controlButtons = document.querySelectorAll(
      '.announcement-top__nav-item--next, .announcement-top__nav-item--prev',
    );
    controlButtons.forEach((button) => {
      button.addEventListener('click', () => {
        window.setTimeout(() => {
          slider.play();
        }, slider.getInfo('speed'));
      });
    });
  },
};

document.addEventListener('DOMContentLoaded', () => {
  BreakingNewsBlock.init();
});
